import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { userToolStore, salaStore } from '@/store';
let SalaList = class SalaList extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Nome',
                sortable: true,
                value: 'nome',
                align: 'left',
            },
            {
                text: 'Capacidade',
                sortable: true,
                value: 'capacidade',
                align: 'left',
            },
            {
                text: 'Ativa',
                sortable: true,
                value: 'ativa',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Sala) {
            return userToolStore.userToolsIndexed.Sala[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Sala) {
            return userToolStore.userToolsIndexed.Sala[0].allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Sala) {
            return userToolStore.userToolsIndexed.Sala[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Sala) {
            return userToolStore.userToolsIndexed.Sala[0].allow_delete;
        }
    }
    get salas() {
        return salaStore.salas;
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão da sala?',
            text: 'Após exclusão a sala não pode ser recuperada!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                // @ts-ignore
                await salaStore.deleteSala(id);
                // @ts-ignore
                await salaStore.getSalas();
                this.loading = false;
            }
        });
    }
    async mounted() {
        this.loading = true;
        await salaStore.getSalas();
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-salas-edit', params: { id } });
    }
};
SalaList = __decorate([
    Component
], SalaList);
export default SalaList;
